import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const OpenClose = ({ data }) => {
    return (
        <>
            {/* Will be added in future */}
            {/*data.childMarkdownRemark.frontmatter.type ? null : (<span className="dot"></span>)*/}
            <GatsbyImage
                image={getImage(data.childMarkdownRemark.frontmatter.image)}
                className="card-img rounded-xl open"
                alt=""
            />
            <div className="card-body d-flex flex-column center">
                <div className="text-center text-dark fw-bold">
                    {data.childMarkdownRemark.frontmatter.title}
                </div>
            </div>
        </>
    )
}
