import React from "react"
import { Link } from "gatsby"
import { OpenClose } from "./OpenClose" 

export const Collection = ({ data, baseUrl = "", noDataTxt}) => {
  return (
    <div className="row justify-content-center align-items-center row-cols-1 row-cols-md-2 row-cols-lg-3">
      {data.length > 0 ? (
        data.map((el, index) => (
          <Link
            className="col card shadow"
            to={`${baseUrl + el.childMarkdownRemark.frontmatter.slug}`}
            key={index}
          >
            <OpenClose data={el} />
          </Link>
        ))
      ) : (
          <div className="center fw-bold fs-3 mt-5 text-center">{noDataTxt ? noDataTxt : "No Data Available"}</div>
      )}
    </div>
  )
}
